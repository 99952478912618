<template>
  <div>
    <b-button
      v-if="showCreateButton"
      variant="primary"
      class="action"
      block
      @click="handleCalendarMeetingOpen(false)"
    >
      <feather-icon icon="PlusCircleIcon" size="20" />
      <span class="ml-50">
        {{ $t('calendar.new-meeting') }}
      </span>
    </b-button>
    <b-button
      v-if="hasMeetingsSlotsAddOn"
      variant="success"
      class="action mb-3"
      block
      @click="handleCalendarMeetingOpen(true)"
    >
      <feather-icon icon="EditIcon" size="20" />
      <span class="ml-50">
        {{ $t('calendar.action.availability') }}
      </span>
    </b-button>
    <!--     <div class="mt-3">
      <h6 class="text-muted">
        {{ $t('calendar.my-events') }}
      </h6>
      <ul class="list-unstyled mt-50">
        <li v-for="(calendarOption, index) in calendarOptions" :key="index" class="mb-50">
          <div
            class="d-inline-block mr-50 reference-icon"
            :class="calendarOption.className"
          />
          <span class="font-weight-bold">
            {{ $t(calendarOption.label) }}
          </span>
        </li>
      </ul>
    </div> -->
    <request-meeting-modal
      v-model="isRequestMeetingVisible"
      :member="selectedMember"
      :with-shared="false"
      @add-event="handleCreate"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

import CalendarOptions from '@/views/apps/calendar/constants/CalendarOptions';

import RequestMeetingModal from '@/views/apps/member/modals/RequestMeetingModal.vue';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';

import eventsStoreModule from '@/views/apps/events/store/eventsStoreModule';
import { EVENTS_STORE_MODULE_NAME } from '@/views/apps/events/constants/events-store-constants';

export default {
  name: 'CalendarReferences',
  components: { BButton, RequestMeetingModal },
  props: {
    hideEventCreation: Boolean,
  },
  data() {
    return {
      isChatSidebarVisible: false,
      isRequestMeetingVisible: false,
      selectedMember: null,
    };
  },
  computed: {
    calendarOptions() {
      return CalendarOptions;
    },
    showCreateButton() {
      return !this.hideEventCreation && (this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].meetings
        || this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].meetingsSlots);
    },
    hasMeetingsSlotsAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].meetingsSlots;
    },
  },
  async beforeCreate() {
    if (!this.$store.hasModule(EVENTS_STORE_MODULE_NAME)) {
      this.$store.registerModule(EVENTS_STORE_MODULE_NAME, eventsStoreModule);
    }
  },
  methods: {
    handleCalendarMeetingOpen(isMyself) {
      this.selectedMember = isMyself ? {
        ...this.$store.getters.loggedMember,
        userKey: this.$store.getters.loggedUser.key,
      } : null;
      this.isRequestMeetingVisible = true;
    },
    handleCreate() {
      this.$emit('add-event');
    },
  },
};
</script>

<style lang="scss" scoped>
.reference-icon {
  border-radius: 2px;
  height:10px;
  width:10px;
}
.action {
  line-height: 20px;
}
</style>
