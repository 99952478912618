<template>
  <b-modal
    :visible="visible"
    title-tag="div"
    centered
    footer-class="justify-content-start border-0 pb-2"
    :hide-footer="!isEvent && !canChangeInvitation && !isPastMeeting"
    size="lg"
    @change="$emit('update:visible', $event)"
    @show="$emit('show', $event)"
  >
    <template #modal-header="{ close }">
      <b-row class="w-100 justify-content-between">
        <b-col cols="8" lg="9">
          <h5 class="event-title" :class="event.title ? 'mb-0' : 'mb-1'" style="margin-top: 8px;">
            <div
              class="mr-1 event-title__reference"
              :class="[
                `calendar-event calendar-event-bg calendar-event--${calendarType}`,
                `calendar-event--view calendar-event--subscription-state--${subscriptionState}`,
              ]"
            />
            <div class="event-title__content mb-0">
              {{ $t('calendar.meeting') }}
            </div>
          </h5>
        </b-col>
        <b-col class="d-flex justify-content-end align-items-start p-0">
          <!-- Edit meeting -->
          <b-button
            v-if="canEdit"
            variant="link"
            class="edit-button"
            @click="$emit('update:edit', true)"
          >
            <feather-icon size="21" icon="EditIcon" />
          </b-button>
          <!-- Close detail -->
          <b-button-close @click="close">
            <b-icon-x />
          </b-button-close>
        </b-col>
      </b-row>
    </template>

    <b-spinner
      v-if="loading"
      type="grow"
      small
      class="mx-auto d-block"
    />
    <b-container v-else fluid>

      <b-row class="mt-1 pb-50">
        <b-col cols="12" lg="9">
          <h5 v-if="event.title" class="event-title mb-0">
              {{ event.title }}
          </h5>
          <h5 v-else class="event-title mb-1">
              ---
          </h5>
          <small>
            <calendar-event-dates 
              class="event-title__content text-primary font-weight-bolder pl-0" 
              :event="event" 
            />
          </small>
          <div class="d-flex align-items-center flex-column flex-sm-row mt-1">
            <!-- Add To Calendar button -->
            <b-button
              variant="outline-primary"
              class="calendar-link mr-sm-50 mb-75 mb-sm-0"
              size="sm"
              :class="isSmall?'w-100':''"
              @click="$emit('open-external-calendars', event)"
            >
              {{ $t('events.event-details.add-to-calendar') }}
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="isOnline" class="mt-1 pb-50">
        <b-col>
          <!-- Online meeting -->
          <div class="d-flex align-items-center mb-1">
            <feather-icon :icon="'VideoIcon'" size="19" class="text-dark mr-1" />
            <p class="font-weight-bolder mb-0">
              {{ $t('events.event-details.online-event') }}
            </p>
          </div>
          <!-- Join meeting button -->
          <b-button
            size="sm"
            variant="primary"
            target="_blank"
            :class="isSmall?'w-100':''"
            :href="meetingLocation"
            :to="!event.extendedProps.joinUrl ? eventRoomLocation : undefined"
          >
            {{ $t('calendar.event-details.join-meeting') }}
          </b-button>
          <!-- Meeting link -->
          <div v-if="!event.extendedProps.joinUrl">
            <router-link :to="eventRoomLocation">
              <b>Link: </b>{{ eventRoomLocation.path }}
            </router-link>
          </div>
        </b-col>
      </b-row>
      <b-row v-else class="mt-1 pb-50">
        <b-col>
          <!-- On-Site meeting -->
          <div class="d-flex align-items-center mb-1 mb-sm-0">
            <feather-icon :icon="'MapPinIcon'" size="19" class="text-dark mr-1" />
            <p class="font-weight-bolder mb-0">
              {{ $t('events.event-details.on-site-event') }}
            </p>
          </div>
            <!-- Location link -->
          <div>
            <template v-if="event.extendedProps.location">
              <template v-if="isEvent">
                <b-button
                  variant="link"
                  size="sm"
                  class="mt-50 p-0"
                  :pressed.sync="isLocationsMapVisible"
                >
                  {{
                    isLocationsMapVisible
                      ? $t('calendar.event-details.hide-locations')
                      : $t('calendar.event-details.show-locations')
                  }}
                </b-button>
                <locations-map
                  v-if="isLocationsMapVisible"
                  :item-key="event.id"
                  type="event"
                  class="locations-map mt-50"
                />
              </template>
              <div v-else class="mt-1">
                <div v-if="locations && locations.length > 0">
                  <small v-for="(location, index) in locations" :key="index">
                    <div>
                      <feather-icon icon="MapIcon" size="1x" class="align-middle" />
                      {{ location }}
                    </div>
                  </small>
                </div>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="event.extendedProps.userStatus" class="mt-2">
        <b-col v-if="event.extendedProps.userStatus">
          <small> <user-icon size="1x" /> {{ $t(`calendar.user-status.${event.extendedProps.userStatus}`) }} </small>
        </b-col>
      </b-row>
      <!--       <b-row class="mt-1">
        <b-col>
          <b-button
            variant="link"
            class="p-0 calendar-link"
            @click="$emit('open-external-calendars', event)"
          >
            {{ $t('events.event-details.add-to-calendar') }}
          </b-button>
        </b-col>
      </b-row> -->
      <b-row v-if="!isEvent && event.extendedProps.guests && eventGuests && eventGuests.length" class="mt-2 pb-50">
        <b-col>
          <h5 class="pb-0 mb-1">
            <span class="font-weight-bolder">
              {{ $t('calendar.event-details.attendees') }}
            </span>
            <b-badge pill variant="light" class="attendees-count ml-50">
              {{ eventGuests.length }}
            </b-badge>
          </h5>
          <ul class="px-sm-2 px-0 m-0 list-unstyled">
            <li
              v-for="guest in eventGuests"
              :key="guest.key"
              class="mb-1"
            >
              <user-avatar
                :user="guest"
                size="32"
                class="mr-50"
                :badge-variant="badgeColor(guest)"
              >
                <template v-if="displayBadge(guest)" #badge>
                  <b-icon-check v-if="guest.key === event.extendedProps.ownerKey" />
                  <b-icon-check v-else-if="guest.eventSubscription === subscriptionStates.Confirmed" />
                  <b-icon-question v-else-if="guest.eventSubscription === subscriptionStates.Maybe" />
                  <b-icon-x v-else-if="guest.eventSubscription === subscriptionStates.RejectedByUser" />
                </template>
              </user-avatar>
              {{ guest.name }} {{ guest.surname }}
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row v-if="event.extendedProps.description" class="mt-2 pb-50">
        <b-col>
          <h5 class="pb-0 mb-1 font-weight-bolder">
            {{ $t('calendar.meeting-form.description.label') }}
          </h5>
          <content-with-anchors :content="event.extendedProps.description" />
        </b-col>
      </b-row>
    </b-container>
    <template v-if="isEvent" #modal-footer>
      <b-link :to="eventLocation" class="d-block my-1">
        {{ $t('action.show-more') }}
      </b-link>
    </template>
    <template v-else-if="canChangeInvitation" #modal-footer>
      <h5 class="my-0 ml-1 mr-1 p-0 font-weight-bolder">
        {{ $t('calendar.event-details.reply-action.title') }}
      </h5>
      <b-button
        class="reply-action p-0"
        :class="{ 'reply-action--active': repliedYes }"
        :disabled="repliedYes"
        variant="link"
        @click="handleAcceptAction"
      >
        {{ $t('calendar.event-details.reply-action.yes') }}
      </b-button>
      <b-button
        class="reply-action p-0"
        :class="{ 'reply-action--active': repliedMaybe }"
        :disabled="repliedMaybe"
        variant="link"
        @click="handleMaybeAction"
      >
        {{ $t('calendar.event-details.reply-action.maybe') }}
      </b-button>
      <b-button
        class="reply-action p-0"
        :class="{ 'reply-action--active': repliedNo }"
        :disabled="repliedNo"
        variant="link"
        @click="handleDeclineAction"
      >
        {{ $t('calendar.event-details.reply-action.no') }}
      </b-button>
    </template>
    <template v-else-if="isPastMeeting" #modal-footer>
      <h5 class="my-0 ml-1 mr-1 p-0 font-weight-bolder">
        {{ $t('calendar.event-details.reply-action.title-past') }}
      </h5>
      <b-button
        class="reply-action p-0"
        :class="{ 'reply-action--active': repliedYes }"
        :disabled="repliedYes"
        variant="link"
        @click="handleAcceptAction"
      >
        {{ $t('calendar.event-details.reply-action.yes') }}
      </b-button>
      <b-button
        class="reply-action p-0"
        :class="{ 'reply-action--active': repliedNo }"
        :disabled="repliedNo"
        variant="link"
        @click="handleDeclineAction"
      >
        {{ $t('calendar.event-details.reply-action.no') }}
      </b-button>
    </template>
    <b-modal
      v-model="isVisibleReasonModal"
      :title="$t('calendar.event-details.reason-input-placeholder')"
      hide-footer
    >
      <b-form-textarea
        id="textarea"
        v-model="reasonText"
        :placeholder="$t('calendar.event-details.reason-input-placeholder')"
        rows="3"
        max-rows="6"
      />
      <b-button variant="success" class="mt-1" @click="sendReason()">
        {{ $t('send.button') }}
      </b-button>
    </b-modal>
  </b-modal>
</template>

<script>
import { UserIcon } from 'vue-feather-icons';
import ContentWithAnchors from '@core/components/string-utils/ContentWithAnchors.vue';
import { EventAccessibility, EventSubscriptionState } from '@copernicsw/community-common';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import LocationsMap from '@/views/apps/locations/components/LocationsMap.vue';
import CalendarEventSubscriptionState from '@/views/apps/calendar/constants/CalendarEventSubscriptionState';
import CalendarMeetingType from '@/views/apps/calendar/constants/CalendarMeetingType';
import { isDownSize } from '@/@core/utils/responsive-utils';
import CalendarEventDates from '../CalendarEventDates.vue';
import { log } from 'video.js';

/**
 * Shows the details of a calendar event
 */
export default {
  name: 'CalendarEventViewModal',
  components: {
    UserIcon,
    ContentWithAnchors,
    CalendarEventDates,
    UserAvatar,
    LocationsMap,
  },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    loading: Boolean,
    event: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  data() {
    return {
      isLocationsMapVisible: false,
      isVisibleReasonModal: false,
      reasonText: '',
      attendResponse: '',
    };
  },
  computed: {
    locations() {
      return this.event.extendedProps.event.locations.map((item) => item.address);
    },
    calendarType() {
      return this.event.extendedProps.calendar;
    },
    subscriptionState() {
      return this.event.extendedProps.subscriptionState;
    },
    eventGuests() {
      const { guests } = this.event.extendedProps;
      const finalGuests = guests.filter((guest, index, self) => index === self.findIndex((secondGuest) => (secondGuest.key === guest.key)));
      return finalGuests;
    },
    actualEndMoment() {
      if (this.event.allDay) {
        return this.$moment(this.date).endOf('day');
      }

      if (this.event.end) {
        const end = this.$moment(this.event.end);
        return end.isSameOrAfter(this.timelineMoment) ? this.$moment(this.date).endOf('day') : end;
      }

      return this.$moment(this.event.start).add(30, 'min');
    },
    isEvent() {
      return [CalendarMeetingType.PublicEvent, CalendarMeetingType.PrivateEvent]
        .includes(this.event.extendedProps.calendar);
    },
    isOnline() {
      return this.event.extendedProps.modality === 'online';
    },
    isOld() {
      return this.actualEndMoment.isBefore(this.$moment());
    },
    canChangeInvitation() {
      return !this.isOld && this.event.extendedProps.accessibility !== EventAccessibility.Public;
    },
    isPastMeeting() {
      return this.isOld && this.event.extendedProps.accessibility !== EventAccessibility.Public;
    },
    canJoinOnlineEvent() {
      // it opens 10 minutes before the start date
      return this.isOnline && !this.isOld
        && this.$moment(this.event.start).subtract(10, 'minutes').isSameOrBefore(Date.now(), 'minute');
    },
    repliedYes() {
      return this.event.extendedProps.subscriptionState === CalendarEventSubscriptionState.Confirmed;
    },
    repliedNo() {
      return this.event.extendedProps.subscriptionState === CalendarEventSubscriptionState.Rejected;
    },
    repliedMaybe() {
      return this.event.extendedProps.subscriptionState === CalendarEventSubscriptionState.Maybe;
    },
    meetingLocation() {
      if (this.event.extendedProps.joinUrl) {
        return this.event.extendedProps.joinUrl;
      }
      return undefined;
    },
    eventRoomLocation() {
      return {
        name: 'event-room',
        path: window.location.origin + this.$route.params.communityId+'/events/'+this.event.id+'/room',
        params: {
          communityId: this.$route.params.communityId,
          id: this.event.id,
        },
      };
    },
    eventLocation() {
      return {
        name: 'event',
        params: {
          communityId: this.$route.params.communityId,
          id: this.event.id,
        },
      };
    },
    subscriptionStates() {
      return EventSubscriptionState;
    },
    isSmall() {
      return isDownSize('sm');
    },
  },
  methods: {
    handleAcceptAction() {
      this.$emit('accept-invitation', { event: this.event });
    },
    handleMaybeAction() {
      this.isVisibleReasonModal = true;
      this.attendResponse = 'maybe-invitation';
    },
    handleDeclineAction() {
      this.isVisibleReasonModal = true;
      this.attendResponse = 'decline-invitation';
    },
    sendReason() {
      this.$emit(this.attendResponse, { event: this.event, reason: this.reasonText });
      this.isVisibleReasonModal = false;
    },
    badgeColor(guest) {
      if (guest.eventSubscription === this.subscriptionStates.Confirmed) return 'primary';
      if (guest.eventSubscription === this.subscriptionStates.Maybe) return 'light';
      if (guest.eventSubscription === this.subscriptionStates.RejectedByUser) return 'danger';
      return null;
    },
    displayBadge(guest) {
      if (guest.eventSubscription === this.subscriptionStates.Confirmed
        || guest.eventSubscription === this.subscriptionStates.Maybe
        || guest.eventSubscription === this.subscriptionStates.RejectedByUser) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.event-title {
  position: relative;
  &__reference {
    position: absolute;
    left: 0;
    display: inline-block;
    vertical-align: baseline;
    border-radius: 2px;
    height: 1.07rem; // user $h5 size
    width: 1.07rem;
  }
  &__content {
    padding-left: 2rem;
  }
}
.attendees-count {
  border: 1px solid $dark;
  color: $dark;
  font-weight: $font-weight-normal;
}

.reply-action {
  text-decoration: underline;
  font-weight: $font-weight-normal;
  color: $dark;
  &--active {
    color: $primary;
  }
}
.edit-button {
  padding: 0;
}
.status-icon {
  position: absolute;
  left: 58px;
  margin-top: 20px;
}

.locations-map {
  height: 200px;
}

</style>
