import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import CalendarEventSubscriptionState from '@/views/apps/calendar/constants/CalendarEventSubscriptionState';
import CalendarMeetingType from '@/views/apps/calendar/constants/CalendarMeetingType';
import {
  EventAccessibility, EventStates, EventSubscriptionState, isMeeting,
} from '@copernicsw/community-common';
import moment from 'moment';

/**
 * Maps BE entity CalendarItem to FullCalendar's CalendarEvent.
 *
 * @param {CalendarItem} calendarItem The calendar item entity as fetched from BE.
 * @returns FullCalendar's CalendarEvent entity.
 */
export function mapCalendarItemToCalendarEvent(locale, loggedUserKey, event) {
  let moreThanOneDay = false;
  if (event.endDate) {
    const endMoment = moment(event.endDate);
    const startMoment = moment(event.startDate);

    moreThanOneDay = endMoment.dayOfYear() !== startMoment.dayOfYear()
      || endMoment.dayOfYear() !== startMoment.dayOfYear();
  }

  let meetingType = null;
  if (event.isMeeting === isMeeting.Meeting) {
    meetingType = CalendarMeetingType.Meeting;
  } else if (event.isMeeting === isMeeting.Room) {
    meetingType = CalendarMeetingType.Room;
  } else if (event.isMeeting === isMeeting.Matchmaking) {
    meetingType = CalendarMeetingType.Matchmaking;
  } else if (event.isMeeting === isMeeting.Event) {
    meetingType = event.accessibility === EventAccessibility.Public
      ? CalendarMeetingType.PublicEvent : CalendarMeetingType.PrivateEvent;
  }
  if (event.state === EventStates.Canceled) {
    meetingType = CalendarMeetingType.Canceled;
  }

  let subscriptionState;
  switch (event.EventSubscription) {
    case EventSubscriptionState.Confirmed:
      subscriptionState = CalendarEventSubscriptionState.Confirmed;
      break;
    case EventSubscriptionState.RejectedByAdmin:
    case EventSubscriptionState.RejectedByUser:
      subscriptionState = CalendarEventSubscriptionState.Rejected;
      break;
    case EventSubscriptionState.Maybe:
      subscriptionState = CalendarEventSubscriptionState.Maybe;
      break;
    case EventSubscriptionState.InvitedToJoin:
      subscriptionState = CalendarEventSubscriptionState.InvitedToJoin;
      break;
    case EventSubscriptionState.RequestedToJoin:
      subscriptionState = CalendarEventSubscriptionState.RequestedToJoin;
      break;
    default:
      subscriptionState = null;
      break;
  }
  
  return {
    id: event.key,
    title: translateTranslationTable(locale, event.title),
    start: event.startDate ? new Date(event.startDate) : null,
    end: event.endDate ? new Date(event.endDate) : null,
    allDay: !!event.allDay || moreThanOneDay, // allDay: this will be used to change how it's displayed
    extendedProps: {
      event,
      allDay: !!event.allDay, // allDay: this will be used to store the state
      calendar: meetingType,
      location: (event.locations || [])[0] || null,
      description: translateTranslationTable(locale, event.description),
      isAccepted: event.isAccepted,
      isAlreadySubscribed: event.isAlreadySubscribed,
      ownerKey: event.ownedByUser?.key,
      guests: event.attendeers || [],
      accessibility: event.accessibility,
      subscriptionState,
      modality: event.modality,
      joinUrl: event.joinUrl,
    },
  };
}

/**
 * Maps Backend's Calendar Item response to the object that FullCalendar expects.
 *
 * @param {CalendarItem[]} calendarItems
 * @returns {CalendarEvent[]}
 */
export function mapEventsResponse(locale, loggedUserKey, paginatedEventsResponse) {
  // We are assuming that no pagination is needed for the request.
  // TODO: Allow pagination for big number of calendarItems.
  return paginatedEventsResponse.data.map((event) => mapCalendarItemToCalendarEvent(locale, loggedUserKey, event));
}

/**
 * Maps the fullcalendary evento back to our event format.
 * BEWARE: Information will be lost, i.e. `event` has more information than
 * `mapCalendarEventToEvent(mapCalendarItemToCalendarEvent(event))`
 */
export function mapCalendarEventToEvent(event) {
  return {
    key: event.id,
    title: event.title,
    startDate: new Date(event.start).getTime(),
    endDate: event.end ? new Date(event.end).getTime() : null,
    allDay: event.extendedProps.allDay,
    locations: event.extendedProps.location ? [event.extendedProps.location] : [],
    description: event.extendedProps.description,
    attendeers: event.extendedProps.guests,
  };
}
