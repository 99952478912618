<template>
  <referenced-calendar-widget class="h-100" :class="menuType==='vertical'|| isMobile?'':'mt-5'" editable />
</template>

<script>
import ReferencedCalendarWidget from '@/views/apps/calendar/widgets/ReferencedCalendarWidget.vue';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';

export default {
  components: { ReferencedCalendarWidget },
  mixins: [GridStatusMixin],
  computed: {
    menuType() {
      return this.$store.getters.currentCollective.menu?.layout;
    },
    isMobile() {
      return this.isGridDisabled;
    },
  },
};
</script>
