<template>
  <widget :enable-card="false">
    <div class="header">
      <h5 class="text-capitalize font-weight-bolder mr-1">
        <b-button
          variant="link"
          class="text-dark p-0"
          @click="$emit('sidebar-change', true)"
        >
          <feather-icon
            icon="MenuIcon"
            size="16"
          />
        </b-button>

        <!-- Previous Button -->
        <b-button
          variant="link"
          class="ml-50 text-dark p-0"
          @click="changeMonth(-1)"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            size="16"
          />
        </b-button>

        <!-- Next Button -->
        <b-button
          variant="link"
          class="ml-50 text-dark p-0"
          @click="changeMonth(+1)"
        >
          <feather-icon
            icon="ChevronRightIcon"
            size="16"
          />
        </b-button>

        <span class="ml-1">
          {{ month }} {{ currentYear }}
        </span>
        <b-spinner
          v-if="isLoading"
          class="ml-50"
          :label="$t('events.calendar.message.loading')"
          small
        />
        <b-button
          v-if="!hasSlots"
          variant="link"
          class="text-primary p-0 float-right"
          @click="createNewMeeting"
        >
          <feather-icon
            icon="PlusCircleIcon"
            size="20"
          />
        </b-button>
      </h5>
    </div>
    <b-table-simple class="calendar mt-2" borderless responsive="sm">
      <b-thead>
        <b-tr>
          <b-th v-for="day in weekdays" :key="day" class="calendar__header">
            {{ $t(`calendar.weekday.${day}.short`) }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(week, weekIndex) in monthGrid" :key="weekIndex">
          <b-td
            v-for="(day, dayIndex) in week"
            :key="dayIndex"
            class="day"
            :class="{
              'day--today': isToday(day),
              'day--has-events': eventsPerDay[day] && eventsPerDay[day].length > 0,
            }"
          >
            <component
              :is="eventsPerDay[day] && eventsPerDay[day].length > 0 ? 'b-button' : 'div'"
              variant="link"
              class="text-dark day__button"
              @click="handleDayClick(day)"
            >
              <div class="day__number">
                {{ day }}
              </div>
              <div v-if="eventsPerDay[day]" class="day__events">
                <div
                  v-for="eventId in eventsPerDay[day].slice(0, 4)"
                  :key="eventId"
                  class="calendar-event"
                  :class="getEventClass(eventById[eventId])"
                />
              </div>
              <small v-if="eventsPerDay[day] && eventsPerDay[day].length > 4" class="day__show-more">
                <span class="d-none d-lg-inline">
                  {{ eventsPerDay[day].length - 4 }} More...
                </span>
                <span class="d-inline d-lg-none">
                  {{ eventsPerDay[day].length - 4 }} +
                </span>
              </small>
            </component>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div v-if="opennedDay" class="calendar-details px-0 px-sm-3 py-2">
      <b-button
        v-for="eventId in eventsPerDay[opennedDay]"
        :key="eventId"
        variant="link"
        class="calendar-details__event text-dark p-0 mb-2 d-block text-left"
        @click="handleOpenEvent(eventById[eventId])"
      >
        <div class="pl-2 position-relative">
          <div class="calendar-details__reference" :class="getEventClass(eventById[eventId])" />
          <event-period
            tag="span"
            class="calendar-details__time"
            :start-date="eventById[eventId].start"
            :end-date="eventById[eventId].end"
            :all-day="eventById[eventId].allDay"
          />
        </div>
        <div class="pl-2 mt-50 calendar-details__title">
          {{ eventById[eventId].title }}
        </div>
      </b-button>
    </div>
    <calendar-event-modal
      v-if="opennedEvent"
      v-model="isModalVisible"
      :event="opennedEvent"
      :editable="editable"
      :refetch-events="() => fetchEvents(true)"
      :update-event-in-calendar="updateEventInCalendar"
      :update-invitation-state-in-calendar="updateInvitationStateInCalendar"
      :remove-event-in-calendar="removeEventInCalendar"
      @update:event="opennedEvent = $event"
    />
  </widget>
</template>

<script>
import Vue from 'vue';
import {
  BCard, BButton, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BSpinner,
} from 'bootstrap-vue';
import { EventPackages, EventStates } from '@copernicsw/community-common';

import { mapCalendarItemToCalendarEvent, mapEventsResponse } from '@/views/apps/calendar/helpers/calendar-item-mappers';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import CalendarMeetingType from '@/views/apps/calendar/constants/CalendarMeetingType';
import EventPeriod from '@/views/apps/events/components/EventPeriod.vue';
import Widget from '@core/widgets/Widget.vue';
import CardWidgetMixin from '@core/mixins/widgets/CardWidgetMixin';

import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { EVENTS_ACTIONS, EVENTS_STORE_MODULE_NAME } from '@/views/apps/events/constants/events-store-constants';
import eventsStoreModule from '@/views/apps/events/store/eventsStoreModule';

import BlankCalendarEvent from '../../constants/BlankCalendarEvent';
import CalendarEventModal from '../modals/CalendarEventModal.vue';

export default {
  name: 'MobileCalendar',
  components: {
    BCard, BButton, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BSpinner, EventPeriod, CalendarEventModal, Widget,
  },
  mixins: [CardWidgetMixin],
  props: {
    editable: Boolean,
  },
  data() {
    const today = new Date();
    return {
      currentMonth: today.getMonth(),
      currentYear: today.getFullYear(),
      eventsPerDay: {},
      eventById: {},
      opennedDay: null,
      isLoading: false,

      isModalVisible: false,
      opennedEvent: null,
    };
  },
  computed: {
    weekdays() {
      return ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    },
    monthMoment() {
      const date = new Date();
      date.setMonth(this.currentMonth);
      date.setFullYear(this.currentYear);

      return this.$moment(date).startOf('month');
    },
    month() {
      return this.monthMoment.format('MMM');
    },
    startOfTheMonth() {
      return this.monthMoment.day();
    },
    daysInMonth() {
      return this.monthMoment.daysInMonth();
    },
    monthGrid() {
      const grid = [];
      let weekdayIndex = this.startOfTheMonth;
      let weekIndex = 0;

      for (let day = 1; day <= this.daysInMonth; day += 1) {
        if (!grid[weekIndex]) {
          grid[weekIndex] = new Array(7).fill(null);
        }
        grid[weekIndex][weekdayIndex] = day;
        weekdayIndex += 1;
        if (weekdayIndex > 6) {
          weekdayIndex = 0;
          weekIndex += 1;
        }
      }

      return grid;
    },
    selectedCalendars() {
      return [...Object.values(CalendarMeetingType)];
    },
    hasSlots() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].meetingsSlots;
    },
  },
  watch: {
    selectedCalendars() {
      this.fetchEvents();
    },
  },
  async created() {
    if (!this.$store.hasModule(EVENTS_STORE_MODULE_NAME)) {
      this.$store.registerModule(EVENTS_STORE_MODULE_NAME, eventsStoreModule);
    }
    if (this.$route.query.meeting) {
      const eventKey = this.$route.query.meeting;
      const event = await this.$store.dispatch(EVENTS_ACTIONS.fetchEvent, { eventKey });

      if (event) {
        const eventToOpen = mapCalendarItemToCalendarEvent(
          this.$store.getters.currentLocale,
          this.$store.getters.loggedUser.key,
          event,
        );
        this.handleOpenEvent(eventToOpen);
      }
    }

    this.fetchEvents();
  },
  methods: {
    changeMonth(direction) {
      this.currentMonth += direction;
      if (this.currentMonth === -1) {
        this.currentYear -= 1;
        this.currentMonth = 11;
      } else if (this.currentMonth === 12) {
        this.currentYear += 1;
        this.currentMonth = 0;
      }
      this.fetchEvents();
    },
    isToday(day) {
      if (day === null) {
        return false;
      }

      return this.monthMoment.clone().date(day).isSame(Date.now(), 'day');
    },
    getEventClass(calendarEvent) {
      const typeName = Object.keys(CalendarMeetingType)
        .find((key) => CalendarMeetingType[key] === calendarEvent.extendedProps.calendar);

      return [
        // Background Color
        `calendar-event calendar-event-bg calendar-event--${typeName}`,
        `calendar-event--subscription-state--${calendarEvent.extendedProps.subscriptionState}`,
      ];
    },
    async fetchEvents(skipClean) {
      try {
        this.isLoading = true;
        if (!skipClean) {
          this.eventsPerDay = {};
          this.eventById = {};
        }
        const response = await this.$store
          .dispatch(EVENTS_ACTIONS.fetchEventList, {
            communityKey: this.$store.getters.currentCollective.key,
            sinceStartDate: this.monthMoment.toDate(),
            untilStartDate: this.monthMoment.clone().endOf('month').toDate(),
            count: 100, // FIXME: Allow pagination for very big calendars
            isSubscribed: true,
            excludeStreaming: true,
            states: [
              EventStates.New,
              EventStates.Pending,
              EventStates.Ongoing,
              EventStates.Confirmed,
              EventStates.Finished,
            ],
            eventPackage: EventPackages.EventsCalendarList,
          });

        const events = mapEventsResponse(
          this.$store.state.locale.currentLocale,
          this.$store.getters.loggedUser?.key,
          response.data,
        );

        this.eventsPerDay = events.reduce((eventsPerDay, event) => {
          const startMoment = this.$moment(event.start);
          const newEventsPerDay = {};

          if (event.allDay) {
            const startDay = startMoment.date();

            Vue.set(newEventsPerDay, startDay, [...(eventsPerDay[startDay] || []), event.id]);
          } else {
            const endMoment = event.end ? this.$moment(event.end) : startMoment.clone().add(30, 'minutes');

            for (
              const moment = startMoment.clone();
              moment.isSameOrBefore(endMoment, 'day');
              moment.add(1, 'day')
            ) {
              if (moment.isSame(this.monthMoment, 'month')) {
                const day = moment.date();
                Vue.set(newEventsPerDay, day, [...(eventsPerDay[day] || []), event.id]);
              }
            }
          }

          return {
            ...eventsPerDay,
            ...newEventsPerDay,
          };
        }, {});

        this.eventById = {};
        events.forEach((event) => Vue.set(this.eventById, event.id, event));
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('events.calendar.message.fetching-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }

      this.isLoading = false;
    },
    handleDayClick(day) {
      if (!this.eventsPerDay[day]) {
        this.opennedDay = null;
        return;
      }

      this.opennedDay = day;
    },
    handleOpenEvent(event) {
      this.isModalVisible = true;
      this.opennedEvent = event;
    },
    createNewMeeting() {
      this.opennedEvent = JSON.parse(JSON.stringify(BlankCalendarEvent));
      const startDate = new Date();
      this.opennedEvent.start = startDate.toISOString();
      this.opennedEvent.end = this.$moment(this.opennedEvent.start).add(30, 'minutes').toISOString();
      this.isModalVisible = true;
    },
    updateEventInCalendar(eventData) {
      Vue.set(this.eventById, eventData.id, eventData);
    },
    updateInvitationStateInCalendar(eventId, newState) {
      Vue.set(this.eventById, eventId, {
        ...this.eventById[eventId],
        extendedProps: {
          ...this.eventById[eventId].extendedProps,
          EventSubscription: newState,
        },
      });
    },
    removeEventInCalendar(eventId) {
      Object.values(this.eventsPerDay).forEach((eventsArray) => {
        eventsArray.splice(eventsArray.indexOf(eventId), 1);
      });
      Vue.delete(this.eventById, eventId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.calendar {
  text-align: center;
  &__header {
    background-color: transparent !important;
    font-size: 18px;
  }
  &::v-deep {
    th,
    td  {
      padding: 0;
    }
  }
}

.day {
  position: relative;
  padding-bottom: $spacer !important;
  &__button {
    padding: 0.72rem 2rem;
    @include media-breakpoint-down(md) {
      padding: 0.72rem 1.5rem;
    }
    @include media-breakpoint-down(sm) {
      padding: 0.72rem 0.5rem;
    }
    @include media-breakpoint-down(xs) {
      padding: 0.72rem 0rem;
    }
  }
  &__number {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    margin: auto;
  }
  &--today {
    .day__number {
      background: $primary;
      color: $white;
    }
  }
  &--has-events {
    &:hover {
      outline: 1px solid $primary;
      .day__number {
        background: lighten($primary, 40%);
        color: $white;
      }
    }
  }
  &__events {
    display: flex;
    justify-content: center;
    margin-top: calc($spacer/2);

    .calendar-event {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  &__show-more {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: $text-muted;
  }
}

.calendar-details {
  &__reference {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
  &__time {
    color: $secondary;
  }
  &__title {
    font-size: $h5-font-size;
  }
}
</style>
