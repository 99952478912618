<template>
  <member-setting-container>
    <b-card>
      <b-container fluid>
        <b-row class="mb-1">
          <b-col cols="9" class="d-none d-lg-block">
            <h1>
              {{ $t("calendar.title") }}
            </h1>
          </b-col>
          <b-col cols="3" class="d-none d-lg-block">
            <calendar-references @add-event="handleAddEvent" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <mobile-calendar
              v-if="isMobile"
              :editable="editable"
              @sidebar-change="isSidebarVisible = $event"
            />
            <basic-calendar
              v-else
              class="d-none d-md-block"
              :editable="editable"
              :refetch-events.sync="refetchEvents"
              @sidebar-change="isSidebarVisible = $event"
            />
          </b-col>
        </b-row>
      </b-container>
      <teleport to="body">
        <b-sidebar
          v-model="isSidebarVisible"
          bg-variant="white"
          light
          backdrop
          shadow
          no-header
          width="220px"
        >
          <calendar-references class="px-2 py-3" hide-event-creation />
        </b-sidebar>
      </teleport>
    </b-card>
  </member-setting-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BSidebar,
} from 'bootstrap-vue';
import Teleport from 'vue2-teleport';
import Widget from '@core/widgets/Widget.vue';
import { isDownSize } from '@/@core/utils/responsive-utils';
import CalendarReferences from '../components/CalendarReferences.vue';
import BasicCalendar from '../components/BasicCalendar.vue';
import MobileCalendar from '../components/mobile-calendar/MobileCalendar.vue';
import MemberSettingContainer from '@/views/myself/components/MemberSettingContainer.vue';

// TODO: Update calendar to use proper store cache
// @see https://app.clickup.com/t/1ct2pn1
export default {
  name: 'ReferencedCalendarWidget',
  components: {
    BContainer, 
    BRow, 
    BCol, 
    BSidebar, 
    Teleport, 
    Widget, 
    CalendarReferences, 
    BasicCalendar, 
    MobileCalendar,
    MemberSettingContainer
  },
  props: {
    editable: Boolean,
  },
  data() {
    return {
      isSidebarVisible: false,
      isModalVisible: false,
      opennedEvent: null,
      isMobile: false,

      refetchEvents: false,
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = isDownSize('md');
    },
    handleEventOpen(event) {
      if (!event) {
        return;
      }
      this.isModalVisible = true;
      this.opennedEvent = event;
    },
    handleAddEvent() {
      this.refetchEvents = true;
    },
  },
};
</script>
