<template>
  <div>
    <div v-if="allDayEvent">
      <span>
        {{ calendarDatesLocal }} ({{ $t('local') }})
      </span>
    </div>
    <div v-else>
      <span>
        {{ calendarDatesInLocalTimezone }} ({{ getLocalTimezone }})
      </span>
      <span v-if="!isSameTimezone">
        <br>
        {{ calendarDatesTimezone }}
      </span>
    </div>
  </div>
</template>

<script>
import { getEventDatesRange } from '@/views/apps/events/utils/event-utils';

/**
 * Displays the range of dates for the calendar event.
 */
export default {
  name: 'CalendarEventDates',
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    calendarDatesLocal() {
      // En DB se guarda GMT0, pero del endpoint te viene en tu hora local
      return getEventDatesRange(this.event.start, this.event.end, this.event.extendedProps.allDay, this.locale);
    },
    calendarDatesInLocalTimezone() {
      const startDate = new Date(this.event.start);
      const endDate = new Date(this.event.end);
      const startDateTz1 = startDate.toLocaleString(this.locale);
      const endDateTz1 = endDate.toLocaleString(this.locale);
      return startDateTz1 + ' - ' + endDateTz1;
    },
    calendarDatesTimezone() {
      const timezone = this.timezone;
      const startDate = new Date(this.event.start);
      const endDate = new Date(this.event.end);

      const startDateTz1 = startDate.toLocaleString(this.locale, { timeZone: timezone });
      const endDateTz1 = endDate.toLocaleString(this.locale, { timeZone: timezone });

      /*const startDateTz2 = new Intl.DateTimeFormat(this.locale, {
        dateStyle: "full",
        timeStyle: "long",
        timeZone:  timezone,
      }).format(startDate);
        const endDateTz2 = new Intl.DateTimeFormat(this.locale, {
        dateStyle: "full",
        timeStyle: "long",
        timeZone:  timezone,
      }).format(endDate);*/

      return startDateTz1 + ' - ' + endDateTz1 + ' (' + timezone + ')';
    },
    timezone() {
      return this.event?.extendedProps?.event?.timezone;
    },
    allDayEvent() {
      return this.event?.extendedProps?.allDay;
    },
    getLocalTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    isSameTimezone() {
      return this.getLocalTimezone === this.timezone;
    }
  },
};
</script>

<style lang="scss">
</style>
