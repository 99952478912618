<template>
  <div>
    <component
      :is="modalComponent"
      :visible="visible"
      :loading="isLoading"
      :event="computedEvent"
      :can-edit="isOwner"
      @show="handleBeforeShow"
      @update:visible="$emit('update:visible', $event)"
      @remove-event="handleRemoveEvent"
      @add-event="handleAddEvent"
      @update-event="handleUpdateEvent"
      @accept-invitation="handleAnswerInvitation('accept', $event)"
      @maybe-invitation="handleAnswerInvitation('maybe', $event)"
      @decline-invitation="handleAnswerInvitation('decline', $event)"
      @open-external-calendars="isAddingToExternalCalendar = true"
      @update:edit="isEditing = $event"
    />
    <add-to-external-calendar-modal
      v-if="event.id"
      v-model="isAddingToExternalCalendar"
      :event="transformedEvent"
    />
  </div>
</template>

<script>
import store from '@/store';

import AddToExternalCalendarModal from '@/views/apps/events/components/modals/AddToExternalCalendarModal.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

import { EVENTS_ACTIONS } from '@/views/apps/events/constants/events-store-constants';

import CalendarEventSubscriptionState from '../../constants/CalendarEventSubscriptionState';
import CalendarEventViewModal from './CalendarEventViewModal.vue';
import CalendarEventFormModal from './CalendarEventFormModal.vue';
import { isEventEditable } from '../../helpers/editable-events';
import { mapCalendarEventToEvent, mapCalendarItemToCalendarEvent } from '../../helpers/calendar-item-mappers';

// TODO: Consider moving to /events/calendar/modals
export default {
  name: 'CalendarEventModal',
  components: { CalendarEventViewModal, AddToExternalCalendarModal },
  mixins: [ToastNotificationsMixin],
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: Boolean,
    event: {
      type: Object,
      required: true,
    },
    editable: Boolean,
    refetchEvents: {
      type: Function,
      required: true,
    },
    updateEventInCalendar: {
      type: Function,
      required: true,
    },
    updateInvitationStateInCalendar: {
      type: Function,
      required: true,
    },
    removeEventInCalendar: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isAddingToExternalCalendar: false,
      isEditing: false,
      isLoading: false,
      fetchedEvent: null,
    };
  },
  computed: {
    locale() {
      return this.$store.getters.currentLocale;
    },
    transformedEvent() {
      return mapCalendarEventToEvent(this.computedEvent);
    },
    computedEvent() {
      return this.fetchedEvent || this.event;
    },
    isOwner() {
      return this.event.extendedProps.ownerKey === this.$store.getters.loggedUser?.key;
    },
    modalComponent() {
      if (!this.editable) {
        return CalendarEventViewModal;
      }
      if (!isEventEditable(this.event)) {
        return CalendarEventViewModal;
      }

      if (!this.event.id) {
        return CalendarEventFormModal;
      }

      return this.isOwner && this.isEditing ? CalendarEventFormModal : CalendarEventViewModal;
    },
  },
  methods: {
    async handleBeforeShow() {
      this.fetchedEvent = null;

      if (this.event.id) {
        this.isLoading = true;
        try {
          const event = await this.$store.dispatch(EVENTS_ACTIONS.fetchEvent, {
            eventKey: this.event.id,
            attendeersCount: 100, // We avoid the pagination that BE has done. Right now it's of no use for us.
          });

          this.fetchedEvent = mapCalendarItemToCalendarEvent(
            this.locale,
            this.$store.getters.loggedUser?.key,
            event,
          );
        } catch (error) {
          this.notifyError(this.$t('events.calendar.message.fetching-error'));
          this.$emit('update:visible', false);
        }
        this.isLoading = false;
      }
    },
    async handleRemoveEvent(eventToRemove) {
      await this.$store.dispatch(EVENTS_ACTIONS.cancelEvent, { key: eventToRemove.id });
      this.removeEventInCalendar(eventToRemove.id);
      this.notifyError(this.$t('events.calendar.message.event-removed'), { icon: 'TrashIcon' });
      this.$emit('update:visible', false);
    },

    async handleAddEvent(eventData) {
      try {
        //console.log('eventData:', eventData);
        const event = eventData;
        const locale = this.$store.getters.currentLocale;
        await this.$store.dispatch('createItem', {
          noSet: true,
          item: {
            itemType: 'createMeeting',
            requestConfig: {
              name: { [locale]: event.title },
              description: { [locale]: event.extendedProps.description },
              locations: event.extendedProps.location ? [event.extendedProps.location] : [],
              allDay: event.extendedProps.allDay,
              //timezone: event.timezone,
              withShared: this.withShared,
              showCreated: true,
              modality: event.extendedProps.modality === 'online' ? 1 : 0,
              eventType:
                this.withShared === true ? 27452 : eventData.extendedProps?.calendar === 'Meeting' ? 27413 : null,
              startDate: event.start,
              endDate: event.end ? event.end : null,
              invitedPeople: (event.extendedProps.guests || []).map(({ userKey }) => userKey),
              collectiveKey: this.$store.getters.currentCollective.key,
              isMeeting: 1,
            },
          },
        });   
        this.$emit('add-event', eventData);
        this.notifySuccess(this.$t('calendar.messages.event-added.success'));
        this.refetchEvents();
        this.$emit('update:visible', false);
      } catch {
        this.notifyError(this.$t('calendar.messages.event-added.error'));
      }
      this.refetchEvents();
      this.$emit('update:visible', false);
    },
    async handleUpdateEvent({ newEvent, oldEvent }) {
      const currentGuests = (newEvent.extendedProps.guests || []).map((guest) => guest?.userKey || guest?.key);
      const oldGuests = (oldEvent.extendedProps.guests || []).map((guest) => guest?.userKey || guest?.key);

      const promises = [this.$store.dispatch(EVENTS_ACTIONS.updateEvent, { locale: this.locale, event: newEvent })];

      const invitedGuests = currentGuests.filter((key) => !oldGuests.includes(key));
      const removedGuests = oldGuests.filter((key) => !currentGuests.includes(key));

      if (invitedGuests.length) {
        invitedGuests.push(this.$store.dispatch(
          EVENTS_ACTIONS.inviteGuests,
          { eventKey: newEvent.id, userKeys: invitedGuests },
        ));
      }

      if (removedGuests.length) {
        removedGuests.push(this.$store.dispatch(
          EVENTS_ACTIONS.removeGuests,
          { eventKey: newEvent.id, userKeys: removedGuests },
        ));
      }

      await Promise.all(promises);
      this.updateEventInCalendar(newEvent);
      this.$emit('update:event', newEvent);
      this.isEditing = false;
    },
    async handleAnswerInvitation(answer, event) {
      const InvitationResponseMessages = {
        eventDoesntExist: 'calendar.messages.answer-invitation.error.event-doesnt-exist',
        eventFinished: 'calendar.messages.answer-invitation.error.event-finished',
        eventSubscriptionDoesntExist: 'calendar.messages.answer-invitation.error.event-subscription-doesnt-exist',
        eventSubscriptionIsntPendingOfAcceptation: 'calendar.messages.answer-invitation.error.'
          + 'event-subscription-isnt-pending-of-acceptation',
        unkownError: 'calendar.messages.answer-invitation.error.unkown-error',
        success: 'calendar.messages.answer-invitation.success',
      };
      const { reason, event: eventToChange } = event;

      let action;
      let newState;
      switch (answer) {
        case 'accept':
          action = EVENTS_ACTIONS.acceptInvitation;
          newState = CalendarEventSubscriptionState.Confirmed;
          break;
        case 'maybe':
          action = EVENTS_ACTIONS.maybeInvitation;
          newState = CalendarEventSubscriptionState.Maybe;
          break;
        case 'decline':
          action = EVENTS_ACTIONS.declineInvitation;
          newState = CalendarEventSubscriptionState.Rejected;
          break;
        default:
          return;
      }

      let errorMessage = null;
      try {
        const response = await store.dispatch(action, { eventKey: eventToChange.id, reason });
        if (response.data.eventDoesntExist) {
          errorMessage = InvitationResponseMessages.eventDoesntExist;
        }
        if (response.data.eventFinished) {
          errorMessage = InvitationResponseMessages.eventFinished;
        }
        if (response.data.eventSubscriptionDoesntExist) {
          errorMessage = InvitationResponseMessages.eventSubscriptionDoesntExist;
        }
        if (response.data.eventSubscriptionIsntPendingOfAcceptation) {
          errorMessage = InvitationResponseMessages.eventSubscriptionIsntPendingOfAcceptation;
        }
      } catch {
        errorMessage = InvitationResponseMessages.unkownError;
      }

      if (errorMessage) {
        this.notifyError(this.$t(errorMessage));
      } else {
        this.notifySuccess(this.$t(InvitationResponseMessages.success));
      }

      this.updateInvitationStateInCalendar(eventToChange.id, newState);

      this.$emit('update:visible', false);
    },
  },
};
</script>
