import CalendarMeetingType from '@/views/apps/calendar/constants/CalendarMeetingType';

export function isEventEditable(event) {
  return event.extendedProps.calendar === CalendarMeetingType.Meeting;
}

/**
 * If false it will not allow the event to be dropped.
 *
 * @see https://fullcalendar.io/docs/eventAllow
 * @param {Object} draggedEvent
 * @param {Object} dropInfo The dropInfo object has the following properties:
 * allDay: true or false whether the event was dropped on one of the all-day cells.
 *
 * end: Date. The end of where the draggable event was dropped.
 *
 * endStr: The ISO8601 string representation of the end of where the draggable event was dropped.
 *
 * resource: If the current view is a resource-view, the Resource Object the element was dropped on. This requires one
 * of the resource plugins.
 *
 * start: Date. The beginning of where the draggable event was dropped.
 *
 * startStr: The ISO8601 string representation of the start of where the draggable event was dropped.
 */
export function isEventDroppable(_dropInfo, { _def: event }) {
  return event && isEventEditable(event);
}
