<template>
  <div class="h-100" style="min-height:78vh">
    <small v-if="isLoading">
      {{ $t('events.calendar.message.loading') }}
    </small>
    <full-calendar
      ref="refCalendar"
      :options="calendarOptions"
      class="full-calendar"
    />
    <calendar-event-modal
      :visible="isModalVisible"
      :event="activeEvent"
      :editable="editable"
      :refetch-events="doRefetchEvents"
      :update-event-in-calendar="updateEventInCalendar"
      :update-invitation-state-in-calendar="updateInvitationStateInCalendar"
      :remove-event-in-calendar="removeEventInCalendar"
      @update:visible="(visible) => { isEventHandlerActive = visible }"
      @update:event="activeEvent = $event"
    />
  </div>
</template>

<script>
// ---- Full Calendar -----
import FullCalendar from '@fullcalendar/vue';
// plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
// i18n
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-gb';
import caLocale from '@fullcalendar/core/locales/ca';
// -----------------------

import { EventPackages, EventStates } from '@copernicsw/community-common';

import store from '@/store';
import eventsStoreModule from '@/views/apps/events/store/eventsStoreModule';
import { EVENTS_ACTIONS, EVENTS_STORE_MODULE_NAME } from '@/views/apps/events/constants/events-store-constants';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';

import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

import { isDownSize } from '@core/utils/responsive-utils';

// TODO: everything should be moved out of 'calendar', as it's not a proper app.
import CalendarEventModal from './modals/CalendarEventModal.vue';

import BlankCalendarEvent from '../constants/BlankCalendarEvent';
import CalendarMeetingType from '../constants/CalendarMeetingType';
import { mapCalendarItemToCalendarEvent, mapEventsResponse } from '../helpers/calendar-item-mappers';
import { isEventEditable } from '../helpers/editable-events';

// TODO: Consider moving to /events/calendar
export default {
  name: 'BasicCalendar',
  components: { FullCalendar, CalendarEventModal },
  mixins: [ToastNotificationsMixin],
  props: {
    editable: Boolean,
    hideEventCreation: Boolean,
    refetchEvents: Boolean,
  },
  data() {
    return {
      isEventHandlerActive: false,
      activeEvent: JSON.parse(JSON.stringify(BlankCalendarEvent)),
      isLoading: false,
    };
  },
  computed: {
    canCreateEvents() {
      return !this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].meetingsSlots;
    },
    isModalVisible() {
      const isExistingEvent = !!this.activeEvent?.id;

      // Only open the modal when the event is not user generated (Default) or has a valid id (it's not an empty event).
      // Otherwise it would be used for event creation.
      if (!this.editable) {
        return this.isEventHandlerActive && isExistingEvent;
      }

      return this.isEventHandlerActive && (isExistingEvent || isEventEditable(this.activeEvent));
    },
    calendarApi() {
      return this.$refs.refCalendar.getApi();
    },
    // ------------------------------------------------------------------------
    // calendarOptions
    // * This isn't considered in UI because this is the core of calendar app
    // ------------------------------------------------------------------------
    calendarOptions() {
      return {
        themeSystem: 'bootstrap',
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'timeGridWeek',
        headerToolbar: {
          start: 'prev,next, title',
          end: `dayGridMonth,timeGridWeek,timeGridDay,listMonth${!this.hideEventCreation ? '' : ',createNewMeeting'}`,
        },
        locales: [esLocale, enLocale, caLocale],
        /*
        ? Docs: https://fullcalendar.io/docs/events-function
        */
        events: this.fetchEvents,
        loading: (loading) => {
          this.isLoading = loading;
        },

        /*
          Disable dragging and resizing event
          ? Docs: https://fullcalendar.io/docs/editable
        */
        editable: false,

        /*
          Determines if day names and week names are clickable
          ? Docs: https://fullcalendar.io/docs/navLinks
        */
        navLinks: true,

        /*
          Whether or not to display the text for an event’s date/time.
          ? Docs: https://fullcalendar.io/docs/displayEventTime
        */
        displayEventTime: true,

        /*
          Determines how far forward the scroll pane is initially scrolled.
          ? Docs: https://fullcalendar.io/docs/scrollTime
        */
        scrollTime: '07:00:00',

        /*
          Determines the time-text that will be displayed on each event.
          ? Docs: https://fullcalendar.io/docs/eventTimeFormat
        */
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'short',
        },

        slotLabelFormat: (date) => this.$moment(date.date.hour, ['h:mm A']).format('HH A'),

        eventClassNames({ event: { _def: calendarEvent } }) {
          // TODO: Review event mapper, and recheck if this is needed
          const typeName = Object.keys(CalendarMeetingType)
            .find((key) => CalendarMeetingType[key] === calendarEvent.extendedProps.calendar);

          return [
            // Background Color
            `calendar-event calendar-event--${typeName}`,
            `calendar-event--subscription-state--${calendarEvent.extendedProps.subscriptionState}`,
          ];
        },
        eventClick: ({ event: clickedEvent }) => {
          this.activeEvent = clickedEvent;
          this.isEventHandlerActive = true;
        },

        customButtons: {
          createNewMeeting: {
            text: this.$t('calendar.action.create-new-meeting'),
            click: () => {
              this.activeEvent = JSON.parse(JSON.stringify(BlankCalendarEvent));
              const startDate = new Date();
              this.activeEvent.start = startDate.toISOString();
              this.activeEvent.end = this.$moment(this.activeEvent.value.start).add(30, 'minutes').toISOString();

              this.isEventHandlerActive = true;
            },
          },
        },
        // dateClick: (info) => {
        //   if (!this.canCreateEvents || isDownSize('md')) return;

        //   this.activeEvent = JSON.parse(JSON.stringify(BlankCalendarEvent));
        //   const startDate = new Date(info.date);
        //   this.activeEvent.start = startDate.toISOString();

        //   this.isEventHandlerActive = true;
        // },

        // Get direction from app state (store)
        direction: this.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
        rerenderDelay: 350,
        locale: this.$store.state.locale.currentLocale,
        height: '100%',
        expandRows: true,
      };
    },
  },
  watch: {
    isEventHandlerActive(value) {
      // ? Don't reset event till transition is finished
      if (!value) {
        setTimeout(() => {
          this.clearActiveEvent();
        }, 350);
      }
    },
    refetchEvents(value) {
      if (value) {
        this.doRefetchEvents();
        this.$emit('update:refetchEvents', false);
      }
    },
  },
  async created() {
    if (!this.$store.hasModule(EVENTS_STORE_MODULE_NAME)) {
      this.$store.registerModule(EVENTS_STORE_MODULE_NAME, eventsStoreModule);
    }
    if (this.$route.query.meeting) {
      this.openRouteMeeting();
    }
  },
  methods: {
    doRefetchEvents() {
      this.calendarApi.refetchEvents();
    },
    clearActiveEvent() {
      this.activeEvent = JSON.parse(JSON.stringify(BlankCalendarEvent));
    },
    async openRouteMeeting() {
      const eventKey = this.$route.query.meeting;
      const event = await this.$store.dispatch(EVENTS_ACTIONS.fetchEvent, { eventKey });

      if (event) {
        this.activeEvent = mapCalendarItemToCalendarEvent(
          this.$store.getters.currentLocale,
          this.$store.getters.loggedUser.key,
          event,
        );
        this.isEventHandlerActive = true;
      }
    },
    async fetchEvents(info, successCallback, failureCallback) {
      // If there's no info => Don't make useless API call
      if (!info) return;

      try {
        // Fetch Events from API endpoint
        const response = await store
          .dispatch(EVENTS_ACTIONS.fetchEventList, {
            communityKey: this.$store.getters.currentCollective.key,
            sinceStartDate: info.start.getTime(),
            untilStartDate: info.end.getTime(),
            count: 100, // FIXME: Allow pagination for very big calendars
            isSubscribed: true,
            excludeStreaming: true,
            states: [
              EventStates.New,
              EventStates.Pending,
              EventStates.Ongoing,
              EventStates.Confirmed,
              EventStates.Finished,
            ],
            eventPackage: EventPackages.EventsCalendarList,
          });

        // TODO: Considerar mejorar el mapper para q en extended tenga todo el evento y listo,
        // o q extended sea el evento mismo
        successCallback(mapEventsResponse(
          this.$store.getters.currentLocale,
          this.$store.getters.loggedUser.key,
          response.data,
        ));
      } catch (error) {
        failureCallback(this.$t('events.calendar.message.fetching-error'));
        this.notifyError(this.$t('events.calendar.message.fetching-error'));
      }
    },
    updateEventInCalendar(updatedEventData) {
      this.notifySuccess(this.$t('events.calendar.message.event-updated'));

      const existingEvent = this.calendarApi.getEventById(updatedEventData.id);

      // --- Set event properties except date related ----- //
      // ? Docs: https://fullcalendar.io/docs/Event-setProp
      ['id', 'title'].forEach((propName) => {
        existingEvent.setProp(propName, updatedEventData[propName]);
      });

      // --- Set date related props ----- //
      // ? Docs: https://fullcalendar.io/docs/Event-setDates
      existingEvent.setDates(
        updatedEventData.start,
        updatedEventData.end,
        { allDay: updatedEventData.allDay },
      );

      // --- Set event's extendedProps ----- //
      // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
      Object.entries(updatedEventData.extendedProps).forEach(([propName, propValue]) => {
        existingEvent.setExtendedProp(propName, propValue);
      });
    },
    updateInvitationStateInCalendar(eventId, newState) {
      const existingEvent = this.calendarApi.getEventById(eventId);
      existingEvent.setExtendedProp('subscriptionState', newState);
    },
    removeEventInCalendar(eventId) {
      this.calendarApi.getEventById(eventId).remove();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
    border-bottom-style: none;
}

</style>
